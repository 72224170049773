import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template"];
  static outlets = [ 'dirty' ];

  connect() {
    console.log('dynamic fields connected');
  }

  add(event) {
    event.preventDefault();
    event.currentTarget.insertAdjacentHTML(
      "beforebegin",
      this.templateTarget.innerHTML.replace(
        /__CHILD_INDEX__/g,
        new Date().getTime().toString()
      )
    );
    // dynamic fields helper legt de outlet vast
    this.dirtyOutlets.forEach(dirty => dirty.monitorEdits(event.currentTarget));
  }

  remove(event) {
    event.preventDefault();
    const wrapper = event.target.closest('div.dynamic-fields-wrapper')
    if (wrapper) {
      wrapper.remove()
    }
  }
}
