import { Controller } from "@hotwired/stimulus"
import { get, post, put, patch, destroy } from '@rails/request.js'
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-select-bs5';
import languageNL from 'datatables.net-plugins/i18n/nl-NL.js';

export default class extends Controller {
  static targets = [ 'table', 'column' ]
  static values = {
    subtable: Boolean
  }
  // this.table is een instance property

  tableTargetConnected() {
    console.log('datatable connected');
    if (this.columnTarget.firstChild.nodeValue) {
      this.setup_datatable();
    }
  }

  columns() {
    return this.columnTargets.map((node) => ({data: node.firstChild.nodeValue?.toLowerCase().replace(' ', '_')}));
  }

  reload() {
    this.table.draw();
  }

  columns_with_links() {
    let cols = this.columns();
    cols.push(
      { data: "tools", orderable: false,
        render: function (data, type, row, meta) {
          if (type == 'display') {
            data = '<a data-turbo="false" href="'+row.tools+'"><i class="bi bi-caret-right-fill"></i></a>'
          }
          return data;
        }
      }
    );
    if (this.subtableValue) {
      cols[0].render = function (data, type, row, meta) {
        let target = 'target="_blank" '
        if (type == 'display') {
          data = '<a '+target+'data-turbo_frame="_top" href="'+row.tools+'">'+data+'</a>';
        }
        return data;
      }
    } else {
      cols[0].render = function (data, type, row, meta) {
        let target = ''
        if (type == 'display' && !!data && data.length > 30) {
          data = '<a data-turbo="false" href="'+row.tools+'"><span title="'+data+'">'+data.slice(0,20)+'...</span></a>'
        } else {
          data = '<a data-turbo="false" href="'+row.tools+'">'+data+'</a>'
        }
        return data;
      }
      if (cols[1].data == 'organisatie') {
        cols[1].render = function (data, type, row, meta) {
          if (type == 'display' && !!data && data.length > 22) {
            data = '<span title="'+data+'">'+data.slice(0,20)+'...</span>'
          }
          return data;
        }
      }
      cols[3].render = function (data, type, row, meta) {
        if (type == 'display' && !!data && data.length > 20) {
          data = '<span title="'+data+'">'+data.slice(0,20)+'...</span>';
        }
        return data;
      }
      if (cols[4].data == 'organisatie') {
        cols[4].render = function (data, type, row, meta) {
          if (type == 'display' && !!data && data.length > 20) {
            data = '<a data-turbo="false" href="'+row.tools+'"><span title="'+data+'">'+data.slice(0,20)+'...</span></a>'
          } else {
            data = '<a data-turbo="false" href="'+row.tools+'">'+data+'</a>'
          }
          return data;
        }
      } else {
        cols[4].render = function (data, type, row, meta) {
          if (type == 'display' && !!data && data.length > 20) {
            data = '<span title="'+data+'">'+data.slice(0,20)+'...</span>';
          }
          return data;
        }
      }
    }
    return cols;
  }

  setup_datatable() {
    const table_id = this.element.id
    if (table_id == '') { return; }
    if ($.fn.dataTable.isDataTable('#' + table_id)) {
      return
    }
    const url = $(`#${table_id}`).data('source');
    let options = this.options(url);
    options = this.notLabelsButArrows(options);
    this.table = $('#' + table_id).DataTable(options);
  }

  notLabelsButArrows(options) {
    // options.language.paginate.first = '<i class="bi bi-chevron-double-left"></i>'
    // options.language.paginate.last = '<i class="bi bi-chevron-double-right"></i>'
    options.language.paginate.first = ''
    options.language.paginate.last = ''
    options.language.paginate.previous = '<i class="bi bi-chevron-left"></i>'
    options.language.paginate.next = '<i class="bi bi-chevron-right"></i>'
    return options;
  }

  options(url) {
    var order_default = 'asc'
    if (url.includes('bemiddelingen')) {
      order_default = 'desc'
    }
    return {
      language: languageNL,
      ajax: {
        url: url,
      },
      columns: this.columns_with_links(),
      lengthChange: false,
      ordering: true,
      order: [[0, order_default]],
      searching: true,
      serverSide: true,
      processing: true,
    }
  }

}
